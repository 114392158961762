<template>
  <div>
    <PageHeader />
    <b-overlay>
      <b-card>
        <b-row>
          <b-col cols="12" md="5">
            <LottoTypeSelect
              v-model="selectedLottoType"
              hide-label
            />
          </b-col>
          <b-col cols="12" md="5">
            <LottoGameSelect
              v-model="selectedLottoGame"
              :lotto-type="selectedLottoType"
              hide-label
            />
          </b-col>
          <b-col cols="12" md="2" @click.prevent="fetchData">
            <b-button block variant="success">
              <i class="uil uil-search"></i>
              {{ $t('fields.search') }}
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row class="mb-3">
          <b-col>
            <LimitSelect v-model="selectedLimit" />
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              :placeholder="`${$t(
                'fields.search'
              )} (ชื่อผู้ใช้ / รหัสสมาชิก / ชื่อ)`"
              type="search"
              @keydown.enter="fetchData"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-table
          :busy="isFetchingLottoUserBet"
          :fields="fields"
          :items="filteredList"
          responsive
          show-empty
        >
          <template #cell(status)="data">
            <b-badge :variant="displayLottoBetStatusColor(data.item.status)">
              {{ displayLottoBetStatusLabel(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(createdAt)="data">
            {{ data.value | datetimeSecond }}
          </template>

          <template #cell(manageLottoUserBet)="data">
            <b-button-group>
              <b-dropdown dropright size="sm" variant="info">
                <template #button-content>
                  <i class="uil uil-cog"></i>
                </template>
                <b-dropdown-item :to="`/lotto/user-bet/${data.item.id}`">
                  <i class="uil uil-eye mr-2"></i>
                  {{ $t('buttons.view') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$allowPermission('master:manage.agent')"
                  variant="danger"
                  @click.prevent
                >
                  <i class="uil uil-repeat mr-2"></i>
                  {{ $t('buttons.cancel_lotto') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>

          <template #empty="">
            <p class="text-center text-muted">
              {{ $t('messages.nothing_here') }}
            </p>
          </template>
        </b-table>
        <PaginationInput
          :per-page="limit"
          :total="total"
          @update="(val) => (currentPage = val)"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {displayLottoBetStatusColor, displayLottoBetStatusLabel} from '@src/views/main/lotto/utils';

export default {
  page: {
    title: 'รายการโพย',
  },
  components: {
    LottoTypeSelect: () => import('@components/lotto/lotto-type-select.vue'),
    LottoGameSelect: () => import('@components/lotto/lotto-game-select.vue'),
  },
  data() {
    return {
      openDate: Date(),
      closeDate: Date(),
      resultDate: Date(),
      closeRefundDate: Date(),
      selectedLottoType: '',
      selectedLottoGame: '',
      currentPage: 1,
      selectedLimit: 20,
      selectedMaster: '',
      selectedAgent: '',
      search: '',
      fields: [
        {
          key: 'username',
          label: 'ยูสเซอร์เนม',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'lottoName',
          label: 'ชื่อหวย',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'betAmount',
          label: 'จำนวนแทง',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'winAmount',
          label: 'จำนวนถูก',
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'betCount',
          label: 'จำนวนเลข',
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'createdAt',
          label: 'เวลาแทง',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'manageLottoUserBet',
          label: 'จัดการ',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoUserBet: (state) => state.lotto.isFetchingLottoUserBet,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'lottoUserBetList']),
    filteredList() {
      return this.lottoUserBetList.items
    },
    pagination() {
      return this.lottoUserBetList.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  async created() {
    if (this.$route.query.type) {
      this.selectedLottoType = this.$route.query.type
    }
    if (this.$route.query.lottoGame) {
      this.selectedLottoGame = this.$route.query.lottoGame
      await this.fetchData()
    }
  },
  methods: {
    displayLottoBetStatusColor,
    displayLottoBetStatusLabel,
    ...mapActions(['fetchLottoUserBetByGameId']),
    async fetchData() {
      await this.fetchLottoUserBetByGameId({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
        gameType: this.selectedLottoType,
        gameId: this.selectedLottoGame,
      })
    },
  },
}
</script>
